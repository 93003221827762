<!--
 * @Descripttion:
 * @version:
 * @Author: 顾俊杰
 * @Date: 2020-12-09 16:04:11
 * @LastEditors: 顾俊杰
 * @LastEditTime: 2020-12-14 14:17:30
-->
<template>
  <div>
    <div class="content">
      <!-- 顶部 -->
      <div class="showtop">
        <div class="showimg">
             <img :src="`${prodcp.iconhttp}`" alt="" />
        </div>
        <div class="showtitle">
          <p class="cpname">{{prodcp.cpname}}</p>
          <p class="lookcp">{{prodcp.prodesc}}</p>
        </div>
      </div>
      <!-- 中间灰色背景 -->
      <div class="showbg"></div>
      <!-- 中间产品资料 -->
      <div class="information">
        <p class="bgtitle"><img src="@/assets/nh/bgtitle.png"
                 alt=""
                 class="seemainpic">
        </p>
        <p class="title">产品资料</p>
        <div class="table">
          <div class="ziliao"
               v-for="(item,index) in dataList"
               :key="index"
               @click="cpinformation(item)">
            <div class="lxtupian">
              <img :src="item.img"
                   alt=""
                   class="mainpic">
            </div>
            <p class="ziliaoname">{{item.title}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { selectJhsCpInfo } from '@/api/jhs/productlist/index'
import { getStorage } from '@/lib/util'
export default {
  data () {
    return {
      prodcode: '',
      dataList: [],
      list: [// 产品资料的小图标数组
        {
          img: require('@/assets/nh/nhsx-flb.png'),
          title: '费率表',
          code: 'FLB'
        },
        {
          img: require('@/assets/nh/nhsx-jkgz.png'),
          title: '健康告知',
          code: 'JKGZ'
        },
        {
          img: require('@/assets/nh/nhsx-tbxz.png'),
          title: '投保须知',
          code: 'TBXZ'
        },
        // 以下注释为3个产品资料小图标没有，所以暂时不展示
        // {
        //   img: require('@/assets/nh/nhwx-104.png'),
        //   title: '职业类别',
        //   code: 'ZYFL'
        // },
        // {
        //   img: require('@/assets/nh/nhwx-105.png'),
        //   title: '常见问题',
        //   code: 'CJWT'
        // },
        // {
        //   img: require('@/assets/nh/nhwx-106.png'),
        //   title: '附加条款',
        //   code: 'FJFW'
        // },
        {
          img: require('@/assets/nh/nhsx-bqlp.png'),
          title: '保全理赔',
          code: 'BQLP'
        },
        {
          img: require('@/assets/nh/nhsx-cptk.png'),
          title: '产品条款',
          code: 'CPTK'
        },
        {
          img: require('@/assets/nh/nhsx-cpjs.png'),
          title: '产品介绍',
          code: 'CPJS'
        }
      ],
      prodcp: ''

    }
  },
  created () {
    this.u_s = getStorage('u_s', '')
    this.prodcode = this.$route.query.prodcode// 获取路由上的产品编码
    this.information()
  },
  methods: {
    // 素材展示
    information () {
      // type 传1为查询showmsg产品资料，即外层点击;传2在点击查看PDF文件时调用;
      selectJhsCpInfo({ prodcode: this.prodcode, type: '1' }).then(res => {
        let arr = res.data.data.showfile // pdf文件资料
        this.prodcp = res.data.data.showmsg // 产品资料
        this.list.forEach((item, index) => {
          if (arr) {
            arr.forEach((item1, index1) => {
              if (item.code == item1.code) {
                this.dataList.push(item)
              }
            })
          }
        })
      })
    },
    // 查看pdf的跳转方法
    cpinformation (val) {
      this.$router.push(
        `/xqinformation?prodcode=${this.prodcode}&code=${val.code}`
      )
    }
  }
}
</script>

 <style scoped>
.content {
  height: 100vh;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
.showimg {
  width: 2.5rem;
  height: 1.89rem;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-radius: 0.2rem;
  position: relative;
}
.showimg img{
  width: 2.44rem;
}
.showtop {
  display: flex;
  justify-content: space-between;
  padding: 0.7rem 0.5rem 0 0.5rem;
  height: 3.3rem;
}
.showtitle {
  width: 69%;
}
.cpname {
  width: 5.72rem;
  height: 1.2rem;
  word-break: break-all;
  white-space: pre-wrap;
  word-wrap: break-word;
  font-weight: bold;
  font-size: 0.48rem;
  line-height: 0.53rem;
  color: #1A1A1A;
  font-family: PingFang SC;
}
.lookcp {
  width: 5.72rem;
  margin-top: 0.25rem;
  font-size: 0.35rem;
  font-weight: 400;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-family: PingFang SC;
  line-height: 0.53rem;
  color: #BDBDBD;
}
.showbg{
  height: 0.3rem;
  background-color: #F6F6F6;
}
.information {
  margin-top: 0.5rem;
  padding: 0 0.5rem 0 0.5rem;
}
.bgtitle{
  display: inline-block;
}
.bgtitle img{
  width:0.09rem ;
  height: 0.39rem;
}
.title {
  font-size: 0.48rem;
  font-family: PingFang SC;
  font-weight: bold;
  letter-spacing:0.08rem;
  color: #1A1A1A;
  display: inline-block;
  margin-left: 0.29rem;
}
.mainpic {
  width: 0.6rem;
  height: 0.6rem;
}
.table {
  margin-top: 0.5rem;
  display: flex;
  flex-wrap: wrap;
  height: 3rem;
}
.ziliao {
  width: 25%;
  margin: 0.2rem 0;
  margin-bottom: 0.6rem;
}
.ziliaoname {
  text-align: center;
  color: #333333;
  font-family: PingFang SC;
  font-weight: 800;
  font-size: 0.3rem;
  margin-top: 0.1rem;
}
.lxtupian {
  text-align: center;
}
.hbsc {
  position: absolute;
  bottom: 0;
  width: 100%;
  border-radius: 0 0 0.2rem 0.2rem;
  background-color: rgba(0, 0, 0, 0.5);
  height: 0.6rem;
  display: flex;
  align-items: center;
}
.ztyangshi {
  font-size: 0.3rem;
  color: rgba(255, 255, 255);
  margin-left: 0.4rem;
}
.seemainpic {
  width: 0.4rem;
  height: 0.4rem;
  margin-left: 0.1rem;
}
</style>
